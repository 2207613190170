import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

function SummaryComponent(){
    return(
        <div id="summary">
            <Container>
                <Row>
                    <Col>
                        {/*<h4>Summary</h4>*/}
                        <dl>
                            <dt style={{fontWeight: "normal"}}>
                                Innovative Full Stack Engineer with expertise in cloud-based web applications, leveraging Java, Spring Framework, Hibernate, and SQL for back-end development.
                                With front-end client-side technological integrations of Angular, Nodejs, HTML/CSS, JSON, and Rest APIs.
                                Proficient in DevOps practices including CI/CD pipelines with Jenkins and AWS CloudFormation for infrastructure-as-code cloud deployments.
                            </dt>
                        </dl>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}

export default SummaryComponent