import React from "react";
import ProjectsComponent from "./ProjectsComponent";
import SkillsComponent from "./SkillsComponent";
import Container from 'react-bootstrap/Container';
import FooterComponent from "./FooterComponent";
import ContactLinksComponent from "./ContactLinksComponent";
import NavBarComponent from "./NavBarComponent";
import WorkHistoryComponent from "./WorkHistoryComponent";
import EducationComponent from "./Education";
import SummaryComponent from "./SummaryComponent";

function HeaderComponent(){
    return(
    <div>
        <Container>
        <header className="header">
            <h2 style={{paddingBottom: '30px', paddingTop: '100px', color:  '#ffad33'}}>Full Stack Developer</h2>
        </header>
        </Container>
</div>
    )
}

function BodyComponent(){
    return( 
    <div>
        <HeaderComponent/>
        <NavBarComponent/>
        <SummaryComponent/>
        <SkillsComponent/>
        <WorkHistoryComponent/>
        <ProjectsComponent/>
        <EducationComponent/>
        <ContactLinksComponent/>
        {/* <Container id="btm-spacing" style={{paddingTop: "300px", paddingBottom: "50px"}}></Container> */}
        <FooterComponent/> 
    </div>
    )
}

export default BodyComponent