import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function WorkHistoryComponent(){
    return(
        <Container fluid id="work_history_row">
                <div id="work_history">
                <Row>
                <Col sm={3}></Col>
                </Row> 
                <Row>
                <Col sm={3}></Col>
                    <Col>
                    <Col><h4 style={{paddingBottom:"20px", paddingTop: "50px"}}>Professional Experience</h4></Col>
                        <ul id="work_places">
                        <span className="style2">FANNIE MAE - Remote - May 2022 – Current</span>
                        <div>Full Stack Engineer</div>
                        <li>Develop and maintain backend services and REST APIs with Spring Boot and Swagger OpenAPI</li>
                        <li>Accelerated cloud migration of on-premises systems to achieve completion goals, reducing operational costs</li>
                        <li>Refractor Spring Boot applications to migrate services to the cloud</li>
                        <li>Conduct regular scans for container issues and code vulnerabilities with Fortify, Nexus, and Sonar scans</li>
                        <li>Architect and deploy scalable microservices using Spring Boot Maven with AWS ECS and DynamoDB</li>
                        <li>Orchestrate and schedule jobs to automate workflows with AutoSys</li>
                        <li>Setup CI/CD pipelines with Jenkins and UrbanCode to build, integrate scans, and systematize deploys</li>
                        <li>Mock JUnit Tests with Powermock and Mockito framework</li>
                        <li>Automate tests with Cucumber for Behavior-Driven Development (BBD)</li>
                        <li>Administer responsive Client-side Front-end API integrations with Nodejs and AngularJS</li>
                        <li>Implemented pub/sub architecture using AWS SQS and AWS SNS for asynchronous communication between microservices, enabling event-driven scalability and fault tolerance</li>
                        <li>Mentor junior developers on best coding practices; Assist team with debugging complex issues</li>
                        <p/>
                        <span className="style2">USIS - UNITED STATES INFORMATION SYSTEMS - New York, NY -	            September 2021 – November 2021</span>
                        <div>Associate Engineer</div>
                        <li>Innovate new solutions to integrate Security System APIs and Develop automated scripts</li>
                        <li>Invoke partners’ APIs to create Endpoints with Nodejs and Java Spring to evaluate camera system metrics</li>
                        <li>Commissioned jobs for set up of NVRs and IP Cameras with ExaqVision which enabled systems monitoring</li>
                        <li>Program and Document Access Readers and Netboxes for Lendel S2 Systems; Demonstrating to client on usage</li>
                        <li>Created and answered tickets for troubleshooting, offering support to clients</li>
                        <p/>
                        <span className="style2">BEST BUY - GEEK SQUAD - Levittown, NY -	            May 2021 – November 2021</span>
                        <div>Consultant Agent</div>
                        <li>Troubleshoot and Service Repairs; Record and Document Client Statements and Data into Nova</li>
                        <li>Address and Facilitate Clients with their questions and Provide Technical Support and Education to Clients</li>
                        <li>Assess and Document Open Box Units, Damage Claims, and Process Client Devices</li>
                        <li>Send and Receive Packages, and Check Inventory of Client Units in Store</li>
                        <li>Checking in Clients, Keeping Track of Client Repairs. Updating Clients on their Device Status</li>
                        <li>Offer Services of Best Buy’s and Geek Squad’s Tech Support and Protect Plans to Drive Sales</li>
                        <p/>
                        <span className="style2">NEW YORK INSTITUTE OF TECHNOLOGY - Old Westbury, NY -	            December 2017 – May 2018</span>
                        <div>Independent Developer </div>
                        <li>Experience with Java to build Java-based Applications.</li>
                        <li>Worked on building front-end Web Applications with HTML for markup and CSS for styling and interface design.</li>
                        <li>Leveraged the Bootstrap Framework to build more responsive Web Applications.</li>
                        <li>Utilized Oracle PL/SQL to manage and store data for my databases.</li>
                        <li>Deployed Maven projects with Jenkins and Gitlab on AWS Cloud, as a part of the DevOps pipeline.</li>
                        <li>Worked with Amazon RDS for database setup and Amazon EC2 for hosting cloud-based web applications.</li>
                        <li>Implemented Hibernate as an ORM to create Code-First Object-Based Database Applications.</li>
                        <li>Leveraged Spring MVC and Spring ORM to utilize dependency injection.</li>
                        <p/>
                        <span className="style2">NEW YORK INSTITUTE OF TECHNOLOGY and VISCARDI -	                                 September 2016 – May 2017</span>
                        <div>Team Leader and Developer</div>
                        <li>Created a prototype for a Website Creator designed to be Educational and Accessible for those with disabilities; demonstrated with UML and ERD diagrams.</li>
                        <li>Discussed and collaborated with the people at NYIT/Viscardi about the creation of the project, their vision, organized, and lead the team. Researched, planned, and conceptualized the project.</li>
                        <li>Designed the front-end of the project that would fulfill the requests of Viscardi with HTML5 and CSS.</li>
                        <li>Added user functionality to the website interface with Javascript.</li>
                        <li>Developed the website in Visual Studio, C#, and ASP Webforms.</li>
                        <li>Utilized T-SQL to manage the SQL database.</li>
                        </ul>
                    </Col>
                    <Col sm={3}></Col>
                </Row>
                </div>
                <Col sm={3}></Col>
                <Col sm={3}></Col>

        </Container>
    )
}

export default WorkHistoryComponent