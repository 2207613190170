import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import React from "react";

function EducationComponent(){
    return(
        <div id="education">
            <Container fluid>
                <Row>
                    <Col sm={2}></Col>
                    <Col>
                        <h4 style={{paddingBottom:"20px", paddingRight:"100px"}}>Education</h4>
                        <dl>
                            <dt style={{fontWeight: "normal"}}><span style={{color: "rgb(156, 220, 250)", fontWeight: "bold"}}>NEW YORK TECH</span> <span> – </span>
                                <span style={{fontWeight: "normal"}}> Old Westbury, NY </span>
                                <span> – </span>
                                <span style={{color: "#ffad33", fontWeight: "normal"}}> Bachelors of Computer Science 2017 </span>
                            </dt>
                        </dl>
                    </Col>
                    <Col sm={2}></Col>
                </Row>
            </Container>
        </div>
    )
}

export default EducationComponent