import React from "react";
import Container from "react-bootstrap/esm/Container";
import EmailComponent from "./EmailComponent";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function FooterComponent(){
    return(
            <Container fluid style={{paddingBottom:"100px"}}>
                <Row>
                <Col sm={2}></Col>
                <Col>
                <footer className="footer">
                    <h4>Contact Me:</h4>
                    <ul id="contact-info">
                        <li>Janine Lee</li>
                        <li>janinemllee@gmail.com</li>
                        {/*<li><EmailComponent email={"janinemllee@gmail.com"}/></li>*/}
                    </ul>
                </footer></Col>
                </Row>
            </Container>
    )
}

export default FooterComponent