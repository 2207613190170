import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function SkillsComponent(){
    return(
        <Container fluid id="profile-skill-row">
            <Row>
            <Col sm={3}><div id="profile_img"><img src="/IMG_20210820_154728.jpg" class="img-fluid" alt="face_img"/></div></Col>
                <div id="tech-skills">
                <Row>
                <Col sm={1}></Col>
                </Row>
                    <ul class="skills" style={{paddingRight:"40px"}}>
                    <Col><h4 style={{paddingBottom:"20px", paddingRight:"20px"}}>Technical Skills</h4></Col>
                        <li><span className="style2">Data technologies:</span> SQL, PL/SQL, XML, JSON, AJAX, REST/SOAP Web Services, API, APIGateway, Nginx </li>
                        <li><span className="style2">Server Side:</span> Java, Hibernate, Spring MVC, Spring Boot, Spring Framework, JUnit, Mockito, PowerMock, Cucumber </li>
                        <li><span className="style2">Dev Ops:</span> Maven, Jenkins, Git, Github, Gitlab, Bitbucket, Tomcat, Docker, Amazon CloudFormation, Splunk, Tomcat </li>
                        <li><span className="style2">Cloud:</span> AWS, GCP, Oracle Cloud, RDS, ECS, EC2, S3, Lambda, DynamoDB, SQS/SNS, IAM, Cloudwatch, Autosys </li>
                        <li><span className="style2">Front End:</span> HTML 5, CSS, Bootstrap, Javascript, Angularjs, Reactjs, MUI, Nodejs, Nextjs</li>
                        <li><span className="style2">Other:</span> Copilot, AI, Intellij, Microsoft Teams, Visual Studio, Adobe Photoshop, Linux, Windows, Microsoft Excel</li>
                    </ul>
                </div>
                <Col sm={3}></Col>
                <Col sm={3}></Col>
                <Col sm={3}></Col>
            </Row>
            <Row>
            <Col sm={2}></Col>
            <Col id="skills-used" sm={8}><div id="skills_img"><img src="/skills.svg" className="img-fluid" alt="skills_img"/></div></Col>
            </Row>
        </Container>
    )
}

export default SkillsComponent